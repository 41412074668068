export const replaceRouteWithReload = url => {
  // Get the current URL's search params
  const currentParams = new URLSearchParams(window.location.search);

  // Create a new URL object from the provided url
  const newUrl = new URL(url, window.location.origin);

  // Forward specific GET parameters if they exist
  ['code', 'state'].forEach(param => {
    if (currentParams.has(param)) {
      newUrl.searchParams.set(param, currentParams.get(param));
    }
  });

  // Replace the current URL with the new one
  window.location = newUrl.toString();
};

export const userInitial = name => {
  const parts = name.split(/[ -]/).filter(Boolean);
  let initials = parts.map(part => part[0].toUpperCase()).join('');
  return initials.slice(0, 2);
};
